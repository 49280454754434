<template>
  <div>

    <h1 class="mb-5">Gestión de instituciones propuestas</h1>

    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
      <v-card>
        <v-card-title class="title primary white--text">
          Borrar Institución
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5 text-center">
          <p>¿Estás seguro de borrar la insitución?</p>
          <i><b>{{ dialogDeleteName }}</b></i>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="deleteInstitutionsProposal"
          >
            Borrar
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="dialogDelete = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="approve.dialog"
      width="500"
    >
      <v-card>
        <v-progress-linear
          v-if=" approve.loading "
          color="secondary"
          indeterminate      
        ></v-progress-linear>
        <v-card-title class="title primary white--text">
          Aprobar Institución
          <v-spacer></v-spacer>
          <v-btn
            @click="approve.dialog = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5 text-center">
          <p>¿Estás seguro de aprobar la insitución?</p>
          <i><b v-if="approve.data.hasOwnProperty('ins_nombre')">{{ approve.data.ins_nombre }}</b></i>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="approveInstitution"
          >
            Aprobar
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="approve.dialog = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdate"
      width="90%"
      scrollable
    >
      <v-card>
        <v-progress-linear
          v-if="dialogUpdateLoading"
          color="secondary"
          indeterminate
          height="3"          
        ></v-progress-linear>
        <v-card-title class="title primary white--text">
          Editar Institución
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogUpdate = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="formUpdate.ins_tipid"
                  :items="tipos"
                  item-text="tip_nombre" 
                  item-value="tip_id"       
                  label="Tipo"
                  hide-details
                  clearable
                  dense
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field
                  v-model="formUpdate.ins_iniciales"
                  label="Iniciales"
                  placeholder="Inicales"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="formUpdate.ins_nombre"
                  label="Nombre"
                  placeholder="Inicales"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="formUpdate.ins_nombre_ES"
                  label="Nombre Traducido al Español"
                  placeholder="Nombre Traducido al Español"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>
            <!-- </v-row>
            <v-row class="p-0 m-0">  item-text="ins_pais"
                  item-value="ins_pais"-->
              <v-col cols="12" sm="6" md="3">
                <v-combobox
                  v-model="formUpdate.ins_pais"
                  :items="paisesFiltered"             
                  label="Pais"
                  placeholder="Pais"
                  hide-details
                  dense
                  filled
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-combobox
                  v-model="formUpdate.ins_estado"
                  :items="estadosFiltered"
                  label="Estado"
                  placeholder="Estado"
                  hide-details
                  dense
                  filled
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-combobox
                  v-model="formUpdate.ins_ciudad"
                  :items="ciudadesFiltered"
                  label="Ciudad"
                  placeholder="Ciudad"
                  hide-details
                  dense
                  filled
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="formUpdate.ins_contacto"
                  label="Contacto"
                  placeholder="Contacto"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>
            <!-- </v-row>
            <v-row> -->
              <v-col cols="12">
                <v-text-field
                  v-model="formUpdate.ins_url_web"
                  label="Enlace Web"
                  placeholder="Enlace Web"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="formUpdate.ins_url_logo"
                  label="Enlace Logo"
                  placeholder="Enlace Logo"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="formUpdate.ins_url_foto"
                  label="Enlace foto"
                  placeholder="Enlace foto"
                  hide-details
                  dense
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="6">  
                <v-textarea
                  v-model="formUpdate.ins_comentarios"
                  label="Comentario"
                  hide-details
                  filled                        
                ></v-textarea>
              </v-col>

              <v-col cols="6">  
                <span class="font-weight-medium">Etiquetas identificativas</span>
                <v-combobox
                  v-model="formUpdate.ins_tags"
                  :items="tagList"
                  label="Escribe y selecciona o presiona intro para crear una etiqueta nueva"
                  deletable-chips
                  multiple
                  small-chips
                ></v-combobox>
                <span class="font-weight-medium">Puntuación</span>
                <v-rating
                  v-model="formUpdate.ins_rating"
                  class="mt-2"
                  background-color="grey"
                  color="warning"
                  size="25"
                  length="5"
                  clearable
                  dense
                  hover                
                ></v-rating><span class="caption">(Para restablecer la puntuación pincha de nuevo en la puntuación actual y retira el ratón)</span>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="updateInstitutionsProposal"
          >
            Actualizar
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="dialogUpdate = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="secondary white--text">
              <div><v-icon class="mr-3 subtitle-1" color="white">mdi-plus-box-outline</v-icon><span class="font-weight-medium">Proponer Institución</span></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <v-form ref="formNewItem">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      v-model="form.ins_tipid"
                      :items="tipos"
                      item-text="tip_nombre" 
                      item-value="tip_id"       
                      label="Tipo"
                      hide-details
                      clearable
                      dense
                      filled
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      v-model="form.ins_iniciales"                      
                      label="Iniciales"
                      placeholder="Inicales"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                      v-model="form.ins_nombre"
                      :items="institutionsExisting"
                      label="Nombre en idioma de origen"
                      placeholder="Nombre en idioma de origen"
                      hide-details
                      dense
                      filled
                    ></v-combobox>
                    <!-- <v-text-field
                      v-model="form.ins_nombre"
                      label="Nombre en idioma de origen"
                      placeholder="Nombre en idioma de origen"
                      hide-details
                      dense
                      filled
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.ins_nombre_ES"
                      label="Nombre Traducido al Español"
                      placeholder="Nombre Traducido al Español"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>
                <!-- </v-row>
                <v-row class="p-0 m-0"> -->
                  <v-col cols="12" sm="6" md="3">
                    <v-combobox
                      v-model="form.ins_pais"
                      :items="paisesFiltered"
                      label="Pais en Español"
                      placeholder="Pais en Español"
                      hide-details
                      dense
                      filled
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-combobox
                      v-model="form.ins_estado"
                      :items="estadosFiltered"
                      label="Estado en idioma de origen"
                      placeholder="Estado en idioma de origen"
                      hide-details
                      dense
                      filled
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-combobox
                      v-model="form.ins_ciudad"
                      :items="ciudadesFiltered"
                      label="Ciudad en idioma de origen"
                      placeholder="Ciudad en idioma de origen"
                      hide-details
                      dense
                      filled
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="form.ins_contacto"
                      label="Persona de contacto"
                      placeholder="Persona de contacto"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>
                <!-- </v-row>
                <v-row> -->                  

                  <v-col cols="12">
                    <v-text-field
                      v-model="form.ins_url_web"
                      label="Enlace Web"
                      placeholder="Enlace Web"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="form.ins_url_logo"
                      label="Enlace Logo"
                      placeholder="Enlace Logo"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="form.ins_url_foto"
                      label="Enlace foto"
                      placeholder="Enlace foto"
                      hide-details
                      dense
                      filled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">  
                    <v-textarea
                      v-model="form.ins_comentarios"
                      label="Comentario"
                      hide-details
                      filled                        
                    ></v-textarea>
                  </v-col>

                  <v-col cols="6">  
                    <span class="font-weight-medium">Etiquetas identificativas</span>
                    <v-combobox
                      v-model="form.ins_tags"
                      :items="tagList"
                      label="Escribe y selecciona o presiona intro para crear una etiqueta nueva"
                      deletable-chips
                      multiple
                      small-chips
                    >
                    <!-- :search-input.sync="searchTag" -->
                      <!-- <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No hay resultados coincidentes "<strong>{{ search }}</strong>". Presiona <kbd>intro</kbd> para crear una etiqueta nueva
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template> -->
                    </v-combobox>
                    <span class="font-weight-medium">Puntuación</span>
                    <v-rating
                      v-model="form.ins_rating"
                      class="mt-2"
                      background-color="grey"
                      color="warning"
                      size="25"
                      length="5"
                      clearable
                      dense
                      hover                
                    ></v-rating><span class="caption">(Para restablecer la puntuación pincha de nuevo en la puntuación actual y retira el ratón)</span>
                  </v-col>

                  <v-col cols="12" class="text-right">                    
                    <v-btn
                      @click="addInstitutionsProposal"
                      color="primary"
                    >
                      Proponer Institución
                    </v-btn>
                    <v-btn
                      @click="reset"
                      color="red white--text"
                      class="ml-3"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-5  mb-8">
          <v-card-title class="mb-5">
            <v-icon class="mr-2" color="primary">mdi-view-list</v-icon>Listado Instituciones propuestas ({{ institutionsProposalCount }})
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-select
              v-model="filterTipo"
              :items="tipos"
              item-text="tip_nombre" 
              item-value="tip_nombre"       
              label="Filtro por tipo"
              class="mr-2"
              clearable
              hide-details
              outlined
              dense
            ></v-select>
            
            <v-text-field
              v-model="search"
              append-icon="mdi-table-search"
              label="Buscar"
              class="white mr-2"
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>    

            <v-btn
              @click="reloadData"
              color="primary lighten-3"
              height="36" width="38"
              elevation="1" 
              x-small
            >
              <v-icon color="black" small>mdi-table-refresh</v-icon>
            </v-btn>        
          </v-card-title>

          <v-data-table
            :headers="institutionsProposals.table.headers"
            :items="institutionsProposalsData"
            :search="search"
            :expanded.sync="expanded"
            show-expand
            :loading="loadingInstitutions"
            loading-text="Cargando Datos..."        
            item-key="ins_id"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [25,50,100,-1] }"
          >
            <template v-slot:[`header.ins_comentarios`]="">
              <v-icon size="15">mdi-message-text-outline</v-icon>
            </template>

            <template v-slot:[`item.ins_url_web`]="{ item }">   
              <a v-if="item.ins_url_web" :href="item.ins_url_web" target="_blank">O</a>
               - 
              <a v-if="item.ins_url_web" :href=" 'https://translate.google.com/translate?sl=auto&tl=es&u=' + item.ins_url_web " target="_blank">ES</a>   
            </template>
            <template v-slot:[`item.ins_logo_offline_filename`]="{ item }"> 
              <v-img
                v-if="item.ins_logo_offline_filename"
                :lazy-src=" item.ins_logo_offline_filename ? getBaseUrl + getFilesUrl + 'logos/' + item.ins_logo_offline_filename + '?v=' + Math.random() : '' "            
                :src=" item.ins_logo_offline_filename ? getBaseUrl + getFilesUrl + 'logos/' + item.ins_logo_offline_filename + '?v=' + Math.random() : '' " 
                max-height="40"
                max-width="100"
                contain                
              ></v-img>
              <v-icon v-else small color="grey lighten-2">
                mdi-file-hidden
              </v-icon>
            </template>
            <template v-slot:[`item.ins_nombre`]="{ item }"> 
              <span class="font-weight-medium">{{ item.ins_nombre }}</span> <br>
              <span class="caption">{{ item.ins_nombre_ES }}</span>
            </template>
            <template v-slot:[`item.tip_nombre`]="{ item }"> 
              <span class="font-weight-medium">{{ item.tip_nombre }}</span> <!-- <span v-if="item.ins_estado">/ {{ item.ins_estado }}</span> / <span class="caption">{{ item.ins_ciudad }}</span> -->
            </template>
            <template v-slot:[`item.ins_contacto`]="{ item }"> 
              <span class="caption">{{ item.ins_contacto }}</span>
            </template>
            <template v-slot:[`item.ins_url_foto`]="{ item }"> 
              <v-img
                max-height="40"
                max-width="100"
                :src="item.ins_url_foto"
              ></v-img>
            </template>
            <template v-slot:[`item.ins_insert_date`]="{ item }"> 
              {{ convertDate(item.ins_insert_date, true) }}
            </template>
            <template v-slot:[`item.ins_update_date`]="{ item }"> 
              {{ convertDate(item.ins_update_date, true) }}
            </template>
            <template v-slot:[`item.ins_rating`]="{ item }"> 
              <v-rating
                :value="item.ins_rating"
                background-color="grey lighten-3"
                color="warning"
                size="16"
                length="5"
                dense
                hover                
                readonly
              ></v-rating>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-3 pb-3">
                <v-row>
                  <v-col>
                    <strong>Estado / Ciudad:</strong><br>
                    {{ item.ins_estado ? item.ins_estado : '-' }} / {{ item.ins_ciudad }}
                  </v-col>
                  <v-col>
                    <strong>Persona de contacto:</strong><br>
                    {{ item.ins_contacto ? item.ins_contacto : '-' }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <strong>Comentario:</strong><br>
                    {{ item.ins_comentarios ? item.ins_comentarios : '-' }}
                  </v-col>
                  <v-col>
                    <strong>Tags:</strong><br>
                    <v-chip
                      v-for="(tag, index) in item.ins_tags" :key="index"
                      class="caption mr-2"
                      label
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <strong>Foto:</strong><br>
                    <v-img
                      max-height="200"
                      max-width="400"
                      :src="item.ins_url_foto"
                    ></v-img>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:[`item.opciones`]="{ item }"> 
              <v-btn
                @click="setUpdateDialog(item)"
                color="primary lighten-3"
                height="36" width="38"
                elevation="1" 
                x-small            
              >
                <v-icon color="black" small>mdi-file-document-edit-outline</v-icon>
              </v-btn>
              <v-btn
                @click=" setApproveDialog(item) "
                :disabled=" !hasAlertRights "
                class="ml-2"
                color="secondary"
                height="36" width="38"
                elevation="1" 
                x-small
              >
                <v-icon color="white" small>mdi-text-box-check-outline</v-icon>
              </v-btn>

              <v-btn                
                @click="setDeleteDialog(item)"
                :disabled=" getUserData.usr_role != 'admin' "
                class="ml-2"
                color="secondary"
                height="36" width="38"
                elevation="1" 
                x-small
              >
                <v-icon color="white" small>mdi-trash-can-outline</v-icon>
              </v-btn>              
            </template>
            <template v-slot:[`item.ins_alerta`]="{ item }">
              <v-btn
                @click=" loadNotifications(item.ins_id) " 
                class="ml-2"
                color="grey lighten-3"
                height="36" width="38"
                elevation="1" 
                x-small
              >
                <v-icon :color="setNotificationColor(item)" small>mdi-comment-alert-outline</v-icon>
              </v-btn> 
            </template>
          </v-data-table>
        </v-card>
      </v-col>      
    </v-row>

    <notifications-dialog ref="notificationsDialog" @reload-data="reloadData"></notifications-dialog>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { mixinGlobal } from '@/mixins/mixinGlobal'

  import notificationsDialog from '@/components/NotificationsDialog.vue'

  export default {
    name: 'Institutions',
    mixins: [ mixinGlobal ],
    components: { notificationsDialog },
    data: () => ({
      tipos: [],
      filterTipo: '',
      valid: false,
      dialogDelete: false,
      dialogDeleteName: '',
      dialogDeleteItem: '',
      dialogUpdate: false,
      dialogUpdateItem: '',
      dialogUpdateLoading: false,
      searchTag: null,
      tagList: [],
      addInstitutionsProposalLoading: false,
      form: {
        ins_url_logo: '',
        ins_iniciales: '',
        ins_nombre: '',
        ins_nombre_ES: '',
        ins_tipid: '',
        ins_pais: '',
        ins_estado: '',
        ins_ciudad: '',
        ins_contacto: '',
        ins_url_web: '',
        ins_url_foto: '',
        ins_comentarios: '',
        ins_rating: 0,
        ins_tags: [],
      },
      formUpdate: {
        ins_url_logo: '',
        ins_iniciales: '',
        ins_nombre: '',
        ins_nombre_ES: '',
        ins_tipid: '',
        ins_pais: '',
        ins_estado: '',
        ins_ciudad: '',
        ins_contacto: '',
        ins_url_web: '',
        ins_url_foto: '',
        ins_comentarios: '',
        ins_rating: 0,
        ins_tags: '',
      },
      search: '',
      expanded: [],
      institutionsProposals: {
        loading: false,
        table: {
          headers: [
            { text: 'ID', value: 'ins_id', align: 'right', width: 70 },
            { text: 'Logo', value: 'ins_logo_offline_filename', sortable: false, align: 'center' },
            { text: 'Iniciales', value: 'ins_iniciales', width: 100 },
            { text: 'Nombre', value: 'ins_nombre' },
            { text: 'Tipo', value: 'tip_nombre', width: 200 },
            { text: 'Pais', value: 'ins_pais' },           
            { text: 'Web', value: 'ins_url_web', sortable: false, width: 70 },         
            { text: 'Alta', value: 'ins_insert_date', width: 105 },
            { text: 'Actualiz.', value: 'ins_update_date', width: 105 },
            { text: 'Puntuación', value: 'ins_rating', width: 115 },
            { text: 'Más', value: 'data-table-expand' },
            { text: 'Opciones', value: 'opciones', sortable: false, width: 150, cellClass: 'pe-0' },
            { text: '', value: 'ins_alerta', sortable: true, width: 65, class: 'px-0', cellClass: 'px-2 text-end' },
          ],
        }
      },
      approve: {
        data: {},
        dialog: false,
        loading: false
      },
      loadingInstitutions: false
    }),
    mounted () {
      //this.loadInstitutionsProposals()
      this.loadInstitutions()      
      this.getTipos()
      this.getTags()
    },
    computed: {
      ...mapGetters({
        getInstitutions: 'getInstitutions',
        getBaseUrl: 'getBaseUrl',
        getFilesUrl: 'getFilesUrl',
        getUserData: 'auth/getUserData'
      }),  
      institutionsProposalsData: function() { 
        return this.getInstitutions.filter( ins => ins.ins_aprobado == 0 )
      },
      institutionsApproved: function() {
        return this.getInstitutions.filter( ins => ins.ins_aprobado == 1 )
      },
      paisesFiltered: function (){
        let paises = []
        let raw = [...new Map(this.getInstitutions.map(item => [item['ins_pais'], item])).values()]
        raw.forEach( element => {
          paises.push(element.ins_pais)
        })
        return paises
      },
      estadosFiltered: function (){
        let estados = []
        let raw = [...new Map(this.getInstitutions.map(item => [item['ins_estado'], item])).values()]
        raw.forEach( element => {
          estados.push(element.ins_estado)
        })
        return estados
      },
      ciudadesFiltered: function (){
        let ciudades = []
        let raw = [...new Map(this.getInstitutions.map(item => [item['ins_ciudad'], item])).values()]
        raw.forEach( element => {
          ciudades.push(element.ins_ciudad)
        })
        return ciudades
      },
      stats: function(){
        let data = []
        if( this.getInstitutions && this.tipos ){
          this.tipos.forEach(tipo => {
            let count = this.getInstitutions.filter(function(institution){
                return institution.ins_tipid == tipo.tip_id
              }).length

            data.push({ "tipo": tipo.tip_nombre, "count": count })            
          })   
        }
        return data
      },
      institutionsProposalCount: function(){
        return this.institutionsProposalsData.length
      },
      hasAlertRights(){
        return this.getUserData.usr_role === 'admin' ? true : false
      },
      institutionsExisting(){
        const institutionsAll = []

        this.getInstitutions.forEach( item => {
          let text = item.ins_aprobado ? ' (aprobada)' : ' (propuesta)'
          institutionsAll.push({ 
            text: item.ins_nombre + text,
            value: item.ins_nombre,
            disabled: true
           })
        })
        return institutionsAll
      },
    },
    methods: {
      ...mapActions(['loadInstitutions']),
      getTipos(){
        this.$axios.get('tip_tipos?order=tip_nombre')
        .then( (respuesta) =>{  
          this.tipos = respuesta.data.data          
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })    
      },
      getTags(){
        this.tagList = []
        this.$axios.get('tag_tags?order=tag_nombre')
        .then( (respuesta) =>{  
          let tmp = respuesta.data.data 
          tmp.forEach(element => {
            this.tagList.push(element.tag_nombre)
          });         
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })    
      },
      loadInstitutions(){
        this.loadingInstitutions = true
        this.$store.dispatch('loadInstitutions')
        .then( () => {
          this.loadingInstitutions = false
        })
        .catch( () => {
          this.loadingInstitutions = false
        })
      },
      addInstitutionsProposal(){
        if( this.form.ins_tipid == 0 ){
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Tiene que especificar un tipo!' } )
        }else{
          // SET DATE
          var date;
          date = new Date();
          this.form.ins_insert_date = this.getMysqlDate()
          this.form.ins_insert_usrid = this.getUserData.usr_id

          this.addInstitutionsProposalLoading = true

          this.addTags()

          this.$axios.post('ins_instituciones', this.form)
          .then( (respuesta) =>{  
            this.form = Object.assign({}, {})
            this.$store.dispatch('loadInstitutions') 
            this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Institución propuesta con éxito!' } )

            this.addInstitutionsProposalLoading = false            
          })  
          .catch( (error) =>{
            let  message = '';
            if (error.response.data.hasOwnProperty('message')) {
              message = error.response.data.message;
            }else{
              message = error
            }
            this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
            this.addInstitutionsProposalLoading = false
          })
        }
      },
      setDeleteDialog(item){
        this.dialogDeleteName = item.ins_nombre
        this.dialogDeleteItem = item
        this.dialogDelete = true
      },
      deleteInstitutionsProposal(){
        this.$axios.delete('ins_instituciones?ins_id=' + this.dialogDeleteItem.ins_id )
        .then( (respuesta) =>{  
          this.$store.dispatch('loadInstitutions') 
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Propuesta de Institución borrada con éxito!' } )	

          this.dialogDeleteItem = ''
          this.dialogDelete = false          
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })
      },
      setUpdateDialog(item){
        this.formUpdate = Object.assign({}, item)
        this.formUpdate.ins_logo_offline_filename = item.ins_logo_offline_filename
        this.dialogUpdate = true
      },
      updateInstitutionsProposal(){
        this.$delete(this.formUpdate, 'tip_id')
        this.$delete(this.formUpdate, 'tip_nombre')
        this.$delete(this.formUpdate, 'not_notificaciones')

        // SET DATE
        let date
        date = new Date()
        this.formUpdate.ins_update_date = this.getMysqlDate()
        this.formUpdate.ins_update_usrid = this.getUserData.usr_id

        this.dialogUpdateLoading = true

        this.addTags(true)

        this.$axios.put('ins_instituciones', this.formUpdate )
        .then( (respuesta) =>{  
          this.$store.dispatch('loadInstitutions') 
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Propuesta de Institución actualizada con éxito!' } )	

          this.dialogUpdate = false
          this.dialogUpdateLoading = false
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.dialogUpdateLoading = false
        })        
      },
      reset(){
        this.$refs.formNewItem.reset()
      },
      addTags(update = false){
        let tags = update ? this.formUpdate.ins_tags : this.form.ins_tags
        let promises = []

        if( Array.isArray(tags) ){
          tags.forEach( (tag) => {
            let found = this.tagList.findIndex( item => tag.toLowerCase() === item.toLowerCase())

            if( found < 0 ){
              promises.push(
                this.$axios.post('tag_tags', { "tag_nombre": tag } )
                .then( (respuesta) =>{  
                  //console.log( respuesta )
                  return
                })  
                .catch( (error) =>{
                  let  message = '';
                  if (error.response.data.hasOwnProperty('message')) {
                    message = error.response.data.message;
                  }else{
                    message = error
                  }
                  this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
                  return 
                })
              )
            }
          })
          Promise.all(promises).then(() => 
            this.getTags()
          )
        }
      },
      reloadData(){
        this.loadInstitutions()
        this.getTags()
      },
      loadNotifications(insId){
        this.$refs.notificationsDialog.loadInstitutionNotifications(insId)
      },
      setApproveDialog(item){
        this.approve.data = item
        this.approve.dialog = true
      },
      approveInstitution(){
        this.approve.loading = true

        this.$axios.put('ins_instituciones', { ins_id: this.approve.data.ins_id, ins_aprobado: 1 } )
        .then( (respuesta) =>{ 
          this.reloadData()
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Institución aprobada con éxito!' } )	
          this.approve.loading = false 
          this.approve.dialog = false
        })
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.approve.loading = true
        })   
      },
      setNotificationColor(ins){
        if( ins.ins_alerta ){
          return 'red'
        }
        if( ins.not_notificaciones.length > 0 ){
          return 'primary'
        }else{
          return 'grey lighten-1'
        }
      }
    },
    watch: {
      filterTipo(newValue, oldValue) {
        this.search = newValue
      },
      getInstitutions(newValue, oldValue){
        if( newValue.length < 1 ){
          this.loading = true
        }else{
          this.loading = false
        }
      }
    },
  }
</script>

<style scoped>
  .hover{
    cursor: pointer;
  }
</style>
